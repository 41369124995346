/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; font-display: block;}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-display: block;
}
.is-pink {
  background-color: #d9107b;
  color: #fff;
}

.is-info {
  background-color: #215787 !important;
}

.is-primary {
  background-color: #006556 !important;
}
